import { GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'
import AppSyncClient from '@/services/query/providers/Connection'
import {
  listVehicleSubscriptionByDeliveryPlanIdAndTopic,
  listVehicleSubscriptionBySnAndTopicAndDateTimeRange,
  listVehicleSubscriptionBySnAndTopic,
} from '@/graphql/queries'
import {
  ListVehicleSubscriptionByDeliveryPlanIdAndTopicQuery,
  ListVehicleSubscriptionBySnAndTopicAndDateTimeRangeQuery,
  ListVehicleSubscriptionBySnAndTopicQuery,
  VehicleSubscription as VehicleSubscriptionType,
} from '@/API'
import GraphQLApiBase from '@/services/models/GraphQLApiBase'
import UserSessionModel from '@/services/models/UserSession'
import sensor from '@/types/sensor'
import sensorAlert from '@/types/sensorAlert'
import sensorAlertCount from '@/types/sensorAlertCount'

const SENSOR_MAX_LIMIT = 5000
export default class Sensor extends GraphQLApiBase {
  public static clearCache(): void {
    this._clearCache('Sensor')
  }

  public static async selectByDeliveryPlanId(
    delivery_plan_id: string
  ): Promise<sensor[]> {
    return this.cache(
      'SensorDeliveryPlanID',
      delivery_plan_id,
      this._selectByDeliveryPlanId,
      delivery_plan_id
    )
  }

  public static async selectBySerialNoAndDateTimeRangeWithUser(
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<sensor[]> {
    return this.cache(
      'SensorSerialNumber',
      serial_number + startDateTime + endDateTime,
      this._selectBySerialNoAndDateTimeRangeWithUser,
      {
        serial_number,
        startDateTime,
        endDateTime,
      }
    )
  }

  public static async _selectByDeliveryPlanId(
    delivery_plan_id: string
  ): Promise<sensor[]> {
    const appSyncConnection = AppSyncClient.connection()

    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id || ''
    let nextToken: string | null | undefined = undefined
    let merged: VehicleSubscriptionType[] | null = null

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listVehicleSubscriptionByDeliveryPlanIdAndTopic),
        variables: {
          topic: 'sensor',
          user_id: user_id,
          delivery_plan_id: delivery_plan_id,
          sortDirection: 'DESC',
          nextToken: nextToken,
          limit: SENSOR_MAX_LIMIT,
        },
      })) as GraphQLResult<ListVehicleSubscriptionByDeliveryPlanIdAndTopicQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res?.data?.listVehicleSubscriptionByDeliveryPlanIdAndTopic
          ?.items as VehicleSubscriptionType[]) || []
      )
      nextToken =
        res?.data?.listVehicleSubscriptionByDeliveryPlanIdAndTopic?.nextToken
    }
    return new Promise((resolve) => {
      resolve(Sensor.convertVehicleSubscriptionsToSensors(merged || []))
    })
  }

  public static async _selectBySerialNo(arg: {
    serial_number: string
    max?: number
    orderby?: 'ASC' | 'DESC'
  }): Promise<sensor[]> {
    const appSyncConnection = AppSyncClient.connection()
    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id ?? ''
    let merged: VehicleSubscriptionType[] | null = null

    const res = (await appSyncConnection.query({
      query: gql(listVehicleSubscriptionBySnAndTopic),
      variables: {
        user_id: user_id,
        serial_no: arg.serial_number,
        topic: 'sensor',
        sortDirection: arg.orderby ?? 'ASC',
        limit: arg.max ?? SENSOR_MAX_LIMIT,
      },
    })) as GraphQLResult<ListVehicleSubscriptionBySnAndTopicQuery>
    if (!merged) merged = []
    merged = merged.concat(
      (res.data?.listVehicleSubscriptionBySnAndTopic
        ?.items as VehicleSubscriptionType[]) ?? []
    )

    return new Promise((resolve) => {
      resolve(Sensor.convertVehicleSubscriptionsToSensors(merged || []))
    })
  }

  public static async _selectBySerialNoAndDateTimeRangeWithUser(arg: {
    serial_number: string
    startDateTime: number
    endDateTime: number
  }): Promise<sensor[]> {
    const appSyncConnection = AppSyncClient.connection()
    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id ?? ''
    let merged: VehicleSubscriptionType[] | null = null
    let nextToken: string | null | undefined = undefined

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listVehicleSubscriptionBySnAndTopicAndDateTimeRange),
        variables: {
          user_id: user_id,
          serial_no: arg.serial_number,
          topic: 'sensor',
          startDateTime: arg.startDateTime,
          endDateTime: arg.endDateTime,
          sortDirection: 'ASC',
          limit: SENSOR_MAX_LIMIT,
          nextToken: nextToken,
        },
      })) as GraphQLResult<ListVehicleSubscriptionBySnAndTopicAndDateTimeRangeQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res.data?.listVehicleSubscriptionBySnAndTopicAndDateTimeRange
          ?.items as VehicleSubscriptionType[]) ?? []
      )
      nextToken =
        res.data?.listVehicleSubscriptionBySnAndTopicAndDateTimeRange?.nextToken
    }
    return new Promise((resolve) => {
      resolve(Sensor.convertVehicleSubscriptionsToSensors(merged || []))
    })
  }

  public static splitSensors(
    sensors: sensor[],
    end_time: number,
    range: number,
    count: number
  ): sensor[][] {
    const splited: sensor[][] = []
    let threshold = end_time
    let tmp: sensor[] = []

    const firstSensorTime = sensors[0]?.tm ?? 0

    for (let i = 0; i < count; i++) {
      threshold = end_time - range * i
      tmp = []
      sensors
        .slice()
        .reverse()
        .forEach((s) => {
          if (s.tm <= threshold && s.tm > threshold - range) {
            tmp.push(s)
          }
        })
      splited.push(tmp)
    }
    return splited
  }

  public static hasSensorError(sensors: sensor[]): sensorAlertCount {
    const ret: sensorAlertCount = {
      error: [],
      alert: [],
    }
    sensors.forEach((s) => {
      const alerts = Sensor.getSensorAlert(s)
      alerts.forEach((alert) => {
        if (alert.status == 'error') {
          if (ret.error.indexOf(alert.unit) == -1) ret.error.push(alert.unit)
        } else {
          if (ret.alert.indexOf(alert.unit) == -1) ret.alert.push(alert.unit)
        }
      })
    })
    return ret
  }

  public static getSensorAlert(sensor: sensor): sensorAlert[] {
    const statusKeys = ['U1s', 'U2s', 'U3s', 'U4s', 'U5s', 'U6s', 'U7s', 'U8s']
    const alerts: sensorAlert[] = []
    const lowAlertRegex = /.*01$/
    const highAlertRegex = /.*10$/
    const errorAlertRegex = /.*11$/

    const payloadKeys = Object.keys(sensor)
    statusKeys.forEach((_key, i) => {
      const key = this.convertKey(payloadKeys, _key) as keyof sensor
      if ((sensor[key] as string).match(lowAlertRegex))
        alerts.push({ unit: i + 1, status: 'low' } as sensorAlert)
      if ((sensor[key] as string).match(highAlertRegex))
        alerts.push({ unit: i + 1, status: 'high' } as sensorAlert)
      if ((sensor[key] as string).match(errorAlertRegex))
        alerts.push({ unit: i + 1, status: 'error' } as sensorAlert)
    })
    return alerts
  }

  public static convertVehicleSubscriptionsToSensors(
    vehicleSubscriptions: VehicleSubscriptionType[]
  ): sensor[] {
    const filtered = vehicleSubscriptions.filter(
      (vs: VehicleSubscriptionType | null) => {
        return !!vs
      }
    )
    return filtered.map((vs: VehicleSubscriptionType | null): sensor => {
      const data: sensor = {} as sensor
      if (!vs) {
        return data
      }

      const payload = JSON.parse(vs.payload)
      data['sn'] = vs.serial_no
      data['tm'] = vs.tm
      data['created_at'] = vs.created_at
      data['d11'] = payload.d11 == 'null' ? null : Number(payload.d11)
      data['d12'] = payload.d12 == 'null' ? null : Number(payload.d12)
      data['d21'] = payload.d21 == 'null' ? null : Number(payload.d21)
      data['d22'] = payload.d12 == 'null' ? null : Number(payload.d12)
      data['d31'] = payload.d31 == 'null' ? null : Number(payload.d31)
      data['d32'] = payload.d32 == 'null' ? null : Number(payload.d32)
      data['d41'] = payload.d41 == 'null' ? null : Number(payload.d41)
      data['d42'] = payload.d42 == 'null' ? null : Number(payload.d42)
      data['d51'] = payload.d51 == 'null' ? null : Number(payload.d51)
      data['d52'] = payload.d52 == 'null' ? null : Number(payload.d52)
      data['d61'] = payload.d61 == 'null' ? null : Number(payload.d61)
      data['d62'] = payload.d62 == 'null' ? null : Number(payload.d62)
      data['d71'] = null
      data['d72'] = null
      data['d81'] = null
      data['d82'] = null
      data['dgn'] = payload.dgn
      data['gst'] = payload.gst
      data['id'] = vs.id
      data['lat'] = Number(payload.lat)
      data['lon'] = Number(payload.lon)
      data['ttl_timestamp'] = vs.ttl_timestamp
      data['u1s'] = payload.U1s
      data['u2s'] = payload.U2s
      data['u3s'] = payload.U3s
      data['u4s'] = payload.U4s
      data['u5s'] = payload.U5s
      data['u6s'] = payload.U6s
      data['u7s'] = payload.U7s
      data['u8s'] = payload.U8s
      return data
    })
  }

  private static convertKey(keys: string[], target: string): string {
    let ret = ''
    keys.forEach((key) => {
      if (key.toLowerCase() == target.toLowerCase()) return (ret = key)
    })
    return ret
  }
}
