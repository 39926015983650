import { GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'
import AppSyncClient from '@/services/query/providers/Connection'
import {
  ListVehicleSubscriptionByDeliveryPlanIdAndTopicQuery,
  ListVehicleSubscriptionBySnAndTopicQuery,
  ListVehicleSubscriptionBySnAndTopicAndDateTimeRangeQuery,
  VehicleSubscription as VehicleSubscriptionType,
  Traffic as TrafficType,
  Status as StatusType,
} from '@/API'
import {
  listVehicleSubscriptionByDeliveryPlanIdAndTopic,
  listVehicleSubscriptionBySnAndTopic,
  listVehicleSubscriptionBySnAndTopicAndDateTimeRange,
} from '@/graphql/queries'
import GraphQLApiBase from '@/services/models/GraphQLApiBase'
import position from '@/types/position'
import trafficElement from '@/types/trafficElement'
import UserSessionModel from '@/services/models/UserSession'
import StatusModel from '@/services/models/Status'

const TRAFFIC_MAX_LIMIT = 5000
const TRAFFIC_MAX_LIMIT_FOR_WITH_LIMIT = 100
export default class Traffic extends GraphQLApiBase {
  public static clearCache(): void {
    this._clearCache('Traffic')
  }

  public static async selectByDeliveryPlanId(
    delivery_plan_id: string,
    max?: number,
    orderby?: 'ASC' | 'DESC'
  ): Promise<TrafficType[]> {
    let key = 'TrafficDeliveryPlanID'
    if (max) key += ` max:${max}`
    if (orderby) key += ` orderby:${orderby}`
    return this.cache(key, delivery_plan_id, this._selectByDeliveryPlanId, {
      delivery_plan_id,
      max,
      orderby,
    })
  }

  public static async selectBySerialNumber(
    serial_number: string,
    max?: number,
    orderby?: 'ASC' | 'DESC'
  ): Promise<TrafficType[]> {
    let key = 'TrafficSerialNumber'
    if (max !== undefined) key += ` max:${max}`
    if (orderby) key += ` orderby:${orderby}`
    return this.cache(key, serial_number, this._selectBySerialNumber, {
      serial_number,
      max,
      orderby,
    })
  }

  public static async selectBySerialNoAndDateTimeRangeWithUser(
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<TrafficType[]> {
    return this.cache(
      'TrafficSerialNumber',
      serial_number + startDateTime + endDateTime,
      this._selectBySerialNoAndDateTimeRangeWithUser,
      { serial_number, startDateTime, endDateTime }
    )
  }

  public static convertTrafficsToPositions(
    traffics: TrafficType[]
  ): position[] {
    const positions: position[] = []
    traffics.forEach((t) => {
      const { lon, lat } = <TrafficType>t
      positions.push({
        lat: lat,
        lng: lon,
      })
    })
    return positions
  }

  public static async _selectByDeliveryPlanId(arg: {
    delivery_plan_id: string
    max?: number
    orderby?: 'ASC' | 'DESC'
  }): Promise<TrafficType[]> {
    const appSyncConnection = AppSyncClient.connection()

    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id || ''

    if (!arg.orderby) arg.orderby = 'ASC'

    let limit = TRAFFIC_MAX_LIMIT
    if (arg?.max && arg.max <= TRAFFIC_MAX_LIMIT_FOR_WITH_LIMIT)
      limit = TRAFFIC_MAX_LIMIT_FOR_WITH_LIMIT
    let nextToken: string | null | undefined = undefined
    let merged: TrafficType[] | null = null

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listVehicleSubscriptionByDeliveryPlanIdAndTopic),
        variables: {
          topic: 'traffic',
          user_id: user_id,
          delivery_plan_id: arg.delivery_plan_id,
          sortDirection: arg.orderby,
          nextToken: nextToken,
          limit: limit,
        },
      })) as GraphQLResult<ListVehicleSubscriptionByDeliveryPlanIdAndTopicQuery>
      if (!merged) merged = []

      const data = res.data?.listVehicleSubscriptionByDeliveryPlanIdAndTopic
      const vehicleSubscriptions = data?.items ?? []
      const traffics = Traffic.convertVehicleSubscriptionsToTraffics(
        vehicleSubscriptions as VehicleSubscriptionType[]
      )
      merged = merged.concat(traffics)

      nextToken =
        res?.data?.listVehicleSubscriptionByDeliveryPlanIdAndTopic?.nextToken

      if (arg?.max && merged.length >= arg?.max) {
        merged = merged.splice(0, arg.max)
        break
      }
    }
    if (!merged || merged.length == 0) {
      const statuses = await StatusModel.selectByDeliveryPlanId(
        arg.delivery_plan_id,
        1,
        'DESC'
      )
      const latestStatus = statuses[0]
      if (latestStatus) {
        merged = []
        merged.push(Traffic.createDummyTraffic(latestStatus))
      }
    }
    return new Promise((resolve) => {
      resolve(<TrafficType[]>merged || [])
    })
  }

  public static async _selectBySerialNumber(arg: {
    serial_number: string
    max?: number
    orderby?: 'ASC' | 'DESC'
  }): Promise<TrafficType[]> {
    const appSyncConnection = AppSyncClient.connection()

    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id || ''

    let limit = TRAFFIC_MAX_LIMIT
    if (arg?.max && arg.max <= TRAFFIC_MAX_LIMIT_FOR_WITH_LIMIT)
      limit = TRAFFIC_MAX_LIMIT_FOR_WITH_LIMIT
    let nextToken: string | null | undefined = undefined
    let merged: TrafficType[] | null = null

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listVehicleSubscriptionBySnAndTopic),
        variables: {
          user_id: user_id,
          serial_no: arg.serial_number,
          topic: 'traffic',
          sortDirection: arg.orderby ?? 'ASC',
          limit: limit,
        },
      })) as GraphQLResult<ListVehicleSubscriptionBySnAndTopicQuery>
      if (!merged) merged = []

      const data = res.data?.listVehicleSubscriptionBySnAndTopic
      const vehicleSubscriptions = data?.items ?? []
      const traffics = Traffic.convertVehicleSubscriptionsToTraffics(
        vehicleSubscriptions as VehicleSubscriptionType[]
      )
      merged = merged.concat(traffics)

      nextToken = res?.data?.listVehicleSubscriptionBySnAndTopic?.nextToken

      if (arg?.max && merged.length >= arg?.max) {
        merged = merged.splice(0, arg.max)
        break
      }
    }
    if (!merged || merged.length == 0) {
      const statuses = await StatusModel.selectBySerialNumber(
        arg.serial_number,
        'DESC'
      )
      const latestStatus = statuses[0]
      if (latestStatus) {
        merged = []
        merged.push(Traffic.createDummyTraffic(latestStatus))
      }
    }

    return new Promise((resolve) => {
      resolve(<TrafficType[]>merged)
    })
  }

  public static async _selectBySerialNoAndDateTimeRangeWithUser(arg: {
    serial_number: string
    startDateTime: number
    endDateTime: number
    max?: number
    orderby?: 'ASC' | 'DESC'
  }): Promise<TrafficType[]> {
    const appSyncConnection = AppSyncClient.connection()
    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id ?? ''

    let limit = TRAFFIC_MAX_LIMIT
    if (arg?.max && arg.max <= TRAFFIC_MAX_LIMIT_FOR_WITH_LIMIT)
      limit = TRAFFIC_MAX_LIMIT_FOR_WITH_LIMIT
    let merged: TrafficType[] | null = null
    let nextToken: string | null | undefined = undefined

    while (!merged || nextToken) {
      const params = {
        user_id: user_id,
        serial_no: arg.serial_number,
        topic: 'traffic',
        startDateTime: arg.startDateTime,
        endDateTime: arg.endDateTime,
        sortDirection: arg.orderby ?? 'ASC',
        limit: limit,
        nextToken: nextToken,
      }
      const res = (await appSyncConnection.query({
        query: gql(listVehicleSubscriptionBySnAndTopicAndDateTimeRange),
        variables: params,
      })) as GraphQLResult<ListVehicleSubscriptionBySnAndTopicAndDateTimeRangeQuery>

      if (!merged) merged = []

      const data = res.data?.listVehicleSubscriptionBySnAndTopicAndDateTimeRange
      const vehicleSubscriptions = data?.items ?? []
      const traffics = Traffic.convertVehicleSubscriptionsToTraffics(
        vehicleSubscriptions as VehicleSubscriptionType[]
      )

      merged = merged.concat(traffics)

      nextToken = data?.nextToken

      if (arg?.max && merged.length >= arg?.max) {
        merged = merged.splice(0, arg.max)
        break
      }
    }

    if (!merged || merged.length == 0) {
      const statuses =
        await StatusModel.selectBySerialNoAndDateTimeRangeWithUser(
          arg.serial_number,
          arg.startDateTime,
          arg.endDateTime
        )
      const latestStatus = statuses[statuses.length - 1]

      if (latestStatus) {
        merged = []
        merged.push(Traffic.createDummyTraffic(latestStatus))
      }
    }

    return new Promise((resolve) => {
      resolve(merged ?? [])
    })
  }

  public static convertVehicleSubscriptionsToTraffics(
    vehicleSubscriptions: VehicleSubscriptionType[]
  ): TrafficType[] {
    const filtered = vehicleSubscriptions.filter(
      (vs: VehicleSubscriptionType | null) => {
        return !!vs
      }
    )
    // convert VehicleSubscription to Traffic
    return filtered.map((vs: VehicleSubscriptionType | null): TrafficType => {
      const traffic: TrafficType = {} as TrafficType
      if (!vs) {
        return traffic
      }

      const payload = JSON.parse(vs.payload)
      traffic['id'] = vs.id
      traffic['sn'] = vs.serial_no
      traffic['dgn'] = payload.dgn
      traffic['tm'] = vs.tm
      traffic['sp'] = Number(payload.sp)
      traffic['sf'] = Number(payload.sf)
      traffic['eg'] = Number(payload.eg)
      traffic['ef'] = Number(payload.ef)
      traffic['ig'] = Number(payload.ig)
      traffic['th'] = Number(payload.th)
      traffic['air'] = Number(payload.air)
      traffic['lat'] = Number(payload.lat)
      traffic['lon'] = Number(payload.lon)
      traffic['acx'] = Number(payload.acx)
      traffic['acy'] = Number(payload.acy)
      traffic['acz'] = Number(payload.acz)
      traffic['agx'] = Number(payload.agx)
      traffic['agy'] = Number(payload.agy)
      traffic['agz'] = Number(payload.agz)
      traffic['gex'] = Number(payload.gex)
      traffic['gey'] = Number(payload.gey)
      traffic['gez'] = Number(payload.gez)
      traffic['created_at'] = vs.created_at
      return traffic
    })
  }

  public static calculate_driving_time(
    ascTraffics: trafficElement[],
    start: Date | undefined,
    end: Date | undefined
  ) {
    if (!start || !end) return 0
    let sum = 0
    let begin = false

    for (let i = 0; i < ascTraffics.length - 1; i++) {
      const traffic = ascTraffics[i]
      // skip until start
      if (traffic.date_time < start) continue
      // break over end
      if (traffic.date_time > end) break

      // before start traffic
      if (i != 0 && !begin) i--
      begin = true

      const afterTraffic = ascTraffics[i + 1]
      const drivingStatus = this.judgeDrivingStatus(traffic, afterTraffic)
      if (drivingStatus) {
        const afterTime =
          afterTraffic.date_time > end ? end : afterTraffic.date_time
        const beforeTime = traffic.date_time < start ? start : traffic.date_time
        sum += afterTime.getTime() - beforeTime.getTime()
      }
    }
    return sum / 1000
  }

  public static judgeDrivingStatus(
    beforeTraffic: trafficElement,
    afterTraffic: trafficElement
  ) {
    const threashold = 10 * 60 * 1000
    return (
      beforeTraffic.speed != 0 &&
      afterTraffic.date_time.getTime() - beforeTraffic.date_time.getTime() <=
        threashold
    )
  }

  public static createDummyTraffic(latestStatus: StatusType) {
    return {
      id: 'dummy',
      sn: latestStatus?.serial_no,
      dgn: latestStatus?.delivery_group_no,
      tm: latestStatus?.time,
      sp: 0,
      sf: 0,
      eg: 0,
      ef: 0,
      ig: 0,
      th: 0,
      air: 0,
      lat: latestStatus?.latitude,
      lon: latestStatus?.longitude,
      acx: 0,
      acy: 0,
      acz: 0,
      agx: 0,
      agy: 0,
      agz: 0,
      gex: 0,
      gey: 0,
      gez: 0,
    } as TrafficType
  }

  public static calculateTotalMileage = (
    leavingTime: Date | undefined,
    returningTime: Date | undefined,
    traffics: trafficElement[]
  ): number => {
    if (leavingTime === undefined || returningTime === undefined) {
      return 0
    }
    const filteredTraffics = traffics.filter((v) => {
      const tm = v.date_time.getTime()
      const start = new Date(leavingTime).getTime()
      const end = returningTime ? new Date(returningTime).getTime() : null
      return tm >= start && (!end || tm <= end)
    })

    let totalMileage = 0
    let beforeTime = 0
    let beforeSpeed = 0
    const SKIP_THREASHOLD = 10 * 60 * 1000
    filteredTraffics.forEach((t, i) => {
      const currentTime = t.date_time.getTime()
      // skip first Traffic and engine off
      if (i != 0 && beforeTime + SKIP_THREASHOLD >= currentTime) {
        totalMileage +=
          ((beforeSpeed / 60 / 60) * (currentTime - beforeTime)) / 1000
      }
      beforeTime = t.date_time.getTime()
      beforeSpeed = t.speed
    })
    const len = 5
    return Math.floor(totalMileage * Math.pow(10, len)) / Math.pow(10, len)
  }
}
