import { Status, Traffic, DeviceAlert, DriverSet } from '@/API'
import {
  workStatus,
  STATUS_MAP,
  alertStatus,
  ALERT_STATUS_MAP,
} from '@/services/const'
import position from '@/types/position'
import trafficElement from '@/types/trafficElement'
import alert from '@/types/alert'
import sensor from '@/types/sensor'
import routeEvent from '@/types/routeEvent'
import QueryProvider from '@/services/query/providers/QueryProvider'

import TrafficModel from '@/services/models/Traffic'
import StatusModel from '@/services/models/Status'
import AlertModel from '@/services/models/Alert'
import SensorModel from '@/services/models/Sensor'
import DriverSetModel from '@/services/models/DriverSet'

class AppSyncProvider implements QueryProvider {
  DEFAULT_STATUS = workStatus.WORK_STATUS_IN_SERVICE

  public vehicleStatus = async (
    serial_no: string
  ): Promise<workStatus | undefined> => {
    const status = await this.latestStatus(serial_no)
    const in_service = await this.isInService(serial_no)
    if (!status) {
      return <workStatus>workStatus.WORK_STATUS_NOTHING
    }
    if (!in_service) {
      return <workStatus>workStatus.WORK_STATUS_RETURNING_WAREHOUSE
    }
    const latestDrivingTraffic = await this.latestDrivingTraffic(serial_no)

    return new Promise((resolve) => {
      resolve(
        this.vehicleStatusByStatusAndLatestTraffic(status, latestDrivingTraffic)
      )
    })
  }

  public vehicleStatusByStatusAndLatestTraffic = (
    latestStatus: Status | undefined,
    latestDrivingTraffic: trafficElement
  ): workStatus => {
    // unless latestStatus
    let work = workStatus.WORK_STATUS_LEAVING_WAREHOUSE
    if (latestStatus) {
      work = StatusModel.convertStatus(latestStatus)
    }
    const latestDrivingTime = latestDrivingTraffic?.date_time?.getTime() ?? 0
    const latestStatusTime = (latestStatus?.time ?? 0) * 1000
    const current_time = new Date()
    return StatusModel.investigateVehicleStatus(
      work,
      latestStatusTime,
      latestDrivingTime,
      current_time
    )
  }

  public latestTraffic = async (serial_no: string): Promise<Traffic> => {
    const traffics = await TrafficModel._selectBySerialNumber({
      serial_number: serial_no,
      max: 100,
      orderby: 'DESC',
    })
    return new Promise((resolve) => {
      resolve(traffics[0])
    })
  }

  private latestDrivingTraffic = async (
    serial_no: string
  ): Promise<trafficElement> => {
    // 約15分のデータを取得
    const traffics = await this.getTrafficElementsFilteredBySerialNumber(
      serial_no,
      (15 * 60) / 5,
      'DESC'
    )
    return new Promise((resolve) => {
      traffics.forEach((traffic: trafficElement) => {
        if (traffic.speed > 0) {
          resolve(traffic)
        }
      })
      resolve(traffics[traffics.length - 1])
    })
  }

  public latestWorkStatus = async (serial_no: string): Promise<workStatus> => {
    const status = await this.latestStatus(serial_no)
    return new Promise((resolve) => {
      let vehicleStatus = undefined
      if (status) {
        vehicleStatus = StatusModel.convertStatus(status)
      }
      resolve(<workStatus>vehicleStatus)
    })
  }

  public latestStatus = async (serial_no: string): Promise<Status> => {
    const res = await StatusModel._selectBySerialNumber({
      serial_number: serial_no,
      orderby: 'DESC',
      limit: 1,
    })
    return new Promise((resolve) => {
      resolve(res[0])
    })
  }

  public latestServiceStatus = async (
    serial_no: string
  ): Promise<workStatus> => {
    const res = await StatusModel.selectBySerialNumber(serial_no, 'DESC')

    return new Promise((resolve) => {
      const statuses = res || []
      let vehicleStatus = undefined
      let bool = false

      if (!statuses) {
        resolve(<workStatus>workStatus.WORK_STATUS_NOTHING)
      }
      for (let i = 0; i < statuses.length - 1; i++) {
        vehicleStatus = StatusModel.convertStatus(<Status>statuses[i])

        if (vehicleStatus == workStatus.WORK_STATUS_RETURNING_WAREHOUSE) {
          bool = false
          break
        }
        if (vehicleStatus == workStatus.WORK_STATUS_LEAVING_WAREHOUSE) {
          bool = true
          break
        }
      }
      resolve(<workStatus>StatusModel.convertStatus(<Status>statuses[0], bool))
    })
  }

  public isInService = async (serial_no: string): Promise<boolean> => {
    const res = await StatusModel.selectBySerialNumber(serial_no, 'DESC')

    return new Promise((resolve) => {
      const statuses = res || []
      let vehicleStatus = undefined

      if (!statuses[0]) return resolve(false)

      vehicleStatus = StatusModel.convertStatus(<Status>statuses[0])
      if (vehicleStatus == workStatus.WORK_STATUS_RETURNING_WAREHOUSE) {
        resolve(false)
      } else {
        resolve(true)
      }
    })
  }

  public isLoaded = async (serial_no: string): Promise<boolean> => {
    const res = await StatusModel.selectBySerialNumber(serial_no, 'DESC')

    return new Promise((resolve) => {
      const statuses_log = res || []
      const ar_latest_delivery_plan_statuses =
        AppSyncProvider.findLatestDeliveryPlanStatuses(statuses_log)
      resolve(
        AppSyncProvider.wasCarLoadedAndIsCarStillLoaded(
          ar_latest_delivery_plan_statuses
        )
      )
    })
  }

  private static findLatestDeliveryPlanStatuses(
    arStatuses: Status[]
  ): Status[] {
    const startIndex = AppSyncProvider.findStatusIndex(
      arStatuses,
      workStatus.WORK_STATUS_LEAVING_WAREHOUSE
    )
    if (startIndex > 0) {
      return arStatuses.slice(0, startIndex + 1)
    }
    return []
  }

  private static wasCarLoadedAndIsCarStillLoaded(
    arStatuses: Status[]
  ): boolean {
    const carWasLoaded = AppSyncProvider.wasCarLoaded(arStatuses)
    if (carWasLoaded) {
      return AppSyncProvider.isCarStillLoaded(arStatuses)
    }
    return false
  }

  private static wasCarLoaded(arStatuses: Status[]): boolean {
    const loadedCarStatusIndex = AppSyncProvider.findStatusIndex(
      arStatuses,
      workStatus.WORK_STATUS_ACTUAL_CAR
    )
    return loadedCarStatusIndex >= 0
  }

  private static isCarStillLoaded(arStatuses: Status[]): boolean {
    const loadedCarStatusIndex = AppSyncProvider.findStatusIndex(
      arStatuses,
      workStatus.WORK_STATUS_ACTUAL_CAR
    )
    const ar_statuses_since_loaded_car_status = arStatuses.slice(
      0,
      loadedCarStatusIndex + 1
    )
    const emptyCarStatusIndex = AppSyncProvider.findStatusIndex(
      ar_statuses_since_loaded_car_status,
      workStatus.WORK_STATUS_EMPTY_CAR
    )
    return emptyCarStatusIndex === -1
  }

  private static findStatusIndex(
    arStatuses: Status[],
    needStatus: workStatus
  ): number {
    return arStatuses.findIndex((status) => {
      return StatusModel.convertStatus(status) == needStatus
    })
  }

  public latestPosition = async (serial_no: string): Promise<position> => {
    const res = await this.latestTraffic(serial_no)
    const traffics = [res]
    let data = TrafficModel.convertTrafficsToPositions(traffics)[0]
    if (!data) {
      data = {
        lat: 0.0,
        lng: 0.0,
      }
    }

    return new Promise((resolve) => {
      resolve(<position>data)
    })
  }

  public getTrafficElementsFilteredByDeliveryPlanId = async (
    delivery_plan_id: string,
    max?: number,
    orderby?: 'ASC' | 'DESC'
  ): Promise<trafficElement[]> => {
    const res = await TrafficModel.selectByDeliveryPlanId(
      delivery_plan_id,
      max,
      orderby
    )
    const path = res.map((r) => this.convertTrafficToTrafficElement(r))

    return new Promise((resolve) => {
      resolve(path)
    })
  }

  public getTrafficElementsFilteredBySerialNumber = async (
    serial_no: string,
    max?: number,
    order?: 'ASC' | 'DESC'
  ): Promise<trafficElement[]> => {
    const res = await TrafficModel.selectBySerialNumber(serial_no, max, order)
    const path = res.map((r) => this.convertTrafficToTrafficElement(r))

    return new Promise((resolve) => {
      resolve(path)
    })
  }

  public getRouteEventsFilteredByDeliveryPlanId = async (
    delivery_plan_id: string,
    max?: number,
    orderby?: 'ASC' | 'DESC'
  ): Promise<routeEvent[]> => {
    const res = await StatusModel.selectByDeliveryPlanId(
      delivery_plan_id,
      max,
      orderby
    )
    const routeEvents: routeEvent[] = []
    res.forEach((status) => {
      const routeEvent = this.convertStatusToRouteEvent(status)
      routeEvents.push(routeEvent)
    })

    return new Promise((resolve) => {
      resolve(routeEvents)
    })
  }

  public getAlertsFilteredByDeliveryPlanId = async (
    delivery_plan_id: string
  ): Promise<alert[]> => {
    const res = await AlertModel.selectByDeliveryPlanId(delivery_plan_id)
    return new Promise((resolve) => {
      const alerts: alert[] = []
      res.forEach((r) => {
        const alert = this.convertDeviceAlertToAlert(r)
        alerts.push(alert)
      })
      resolve(alerts)
    })
  }

  public getListStatusBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<routeEvent[]> => {
    const statuses = await StatusModel.selectBySerialNoAndDateTimeRangeWithUser(
      serial_number,
      startDateTime,
      endDateTime
    )
    const routeEvents: routeEvent[] = []
    statuses.forEach((status) => {
      const routeEvent = this.convertStatusToRouteEvent(status)
      routeEvents.push(routeEvent)
    })

    return new Promise((resolve) => {
      resolve(routeEvents)
    })
  }

  public getListAlertBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<alert[]> => {
    const res = await AlertModel.selectBySerialNoAndDateTimeRangeWithUser(
      serial_number,
      startDateTime,
      endDateTime
    )
    const alerts: alert[] = []
    res.forEach((r) => {
      const alert = this.convertDeviceAlertToAlert(r)
      alerts.push(alert)
    })

    return new Promise((resolve) => {
      resolve(alerts)
    })
  }

  public getListTrafficBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<trafficElement[]> => {
    const res = await TrafficModel.selectBySerialNoAndDateTimeRangeWithUser(
      serial_number,
      startDateTime,
      endDateTime
    )
    const traffics = res.map((r) =>
      this.convertTrafficToTrafficElement(r as Traffic)
    )
    return new Promise((resolve) => {
      resolve(traffics ?? [])
    })
  }

  public getListSensorByDeliveryPlanIdWithUser = async (
    delivery_plan_id: string
  ): Promise<sensor[]> => {
    const sensors = await SensorModel.selectByDeliveryPlanId(delivery_plan_id)

    return new Promise((resolve) => {
      resolve(sensors)
    })
  }

  public latestSensor = async (serial_number: string): Promise<sensor> => {
    const sensors = await SensorModel._selectBySerialNo({
      serial_number: serial_number,
      orderby: 'DESC',
    })
    return new Promise((resolve) => {
      resolve(sensors[0])
    })
  }

  public getListSensorBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<sensor[]> => {
    const sensors = await SensorModel.selectBySerialNoAndDateTimeRangeWithUser(
      serial_number,
      startDateTime,
      endDateTime
    )

    return new Promise((resolve) => {
      resolve(sensors)
    })
  }

  public getListDriverSetByDriverPin = async (
    driver_pin: string,
    max?: number,
    orderby?: 'ASC' | 'DESC'
  ): Promise<DriverSet[]> => {
    const driverSets = await DriverSetModel.selectByDriverPin({
      driver_pin: driver_pin,
      max: max,
      orderby: orderby,
    })

    return new Promise((resolve) => {
      resolve(driverSets)
    })
  }

  public convertTrafficToTrafficElement = (
    traffic: Traffic
  ): trafficElement => {
    const { dgn, sn, lat, lon, tm, sp } = <Traffic>traffic
    return {
      dgn: dgn,
      sn: sn,
      lat: lat,
      lng: lon,
      date_time: new Date(tm * 1000),
      speed: sp,
    }
  }

  public convertDeviceAlertToAlert = (deviceAlert: DeviceAlert): alert => {
    const { dgn, tm, lat, lon, alert } = <DeviceAlert>deviceAlert

    const alertStatusString = ALERT_STATUS_MAP[alert]
    return {
      dgn: dgn,
      date_time: new Date(tm * 1000),
      type: <alertStatus>alertStatusString,
      position: {
        lat: lat,
        lng: lon,
      },
    }
  }

  public convertStatusToRouteEvent = (s: Status): routeEvent => {
    const { time, latitude, longitude, status, status_flag } = s
    const key = status_flag + '' + status
    const vehicleStatus = STATUS_MAP[key]
      ? STATUS_MAP[key]
      : this.DEFAULT_STATUS
    return {
      dgn: s.delivery_group_no,
      date_time: new Date(time * 1000),
      type: <workStatus>vehicleStatus,
      position: {
        lat: latitude,
        lng: longitude,
      },
    }
  }
}

export default AppSyncProvider
