import { GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'
import AppSyncClient from '@/services/query/providers/Connection'
import {
  listVehicleSubscriptionByDeliveryPlanIdAndTopic,
  listVehicleSubscriptionBySnAndTopicAndDateTimeRange,
} from '@/graphql/queries'
import {
  ListVehicleSubscriptionByDeliveryPlanIdAndTopicQuery,
  ListVehicleSubscriptionBySnAndTopicAndDateTimeRangeQuery,
  VehicleSubscription as VehicleSubscriptionType,
  DeviceAlert,
} from '@/API'
import GraphQLApiBase from '@/services/models/GraphQLApiBase'
import UserSessionModel from '@/services/models/UserSession'
import { ALERT_STATUS_MAP } from '../const'

const ALERT_MAX_LIMIT = 5000
export default class Alert extends GraphQLApiBase {
  public static clearCache(): void {
    this._clearCache('Alert')
  }

  public static async selectByDeliveryPlanId(
    delivery_plan_id: string
  ): Promise<DeviceAlert[]> {
    return this.cache(
      'AlertDeliveryPlanID',
      delivery_plan_id,
      this._selectByDeliveryPlanId,
      delivery_plan_id
    )
  }

  public static async selectBySerialNoAndDateTimeRangeWithUser(
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<DeviceAlert[]> {
    return this.cache(
      'AlertSerialNumber',
      serial_number + startDateTime + endDateTime,
      this._selectBySerialNoAndDateTimeRangeWithUser,
      {
        serial_number,
        startDateTime,
        endDateTime,
      }
    )
  }

  public static async _selectByDeliveryPlanId(
    delivery_plan_id: string
  ): Promise<DeviceAlert[]> {
    const appSyncConnection = AppSyncClient.connection()

    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id || ''
    let nextToken: string | null | undefined = undefined
    let merged: VehicleSubscriptionType[] | null = null

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listVehicleSubscriptionByDeliveryPlanIdAndTopic),
        variables: {
          topic: 'device_alert',
          user_id: user_id,
          delivery_plan_id: delivery_plan_id,
          sortDirection: 'DESC',
          nextToken: nextToken,
          limit: ALERT_MAX_LIMIT,
        },
      })) as GraphQLResult<ListVehicleSubscriptionByDeliveryPlanIdAndTopicQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res?.data?.listVehicleSubscriptionByDeliveryPlanIdAndTopic
          ?.items as VehicleSubscriptionType[]) || []
      )
      nextToken =
        res?.data?.listVehicleSubscriptionByDeliveryPlanIdAndTopic?.nextToken
    }
    return new Promise((resolve) => {
      resolve(Alert.convertVehicleSubscriptionsToAlerts(merged ?? []))
    })
  }

  public static async _selectBySerialNoAndDateTimeRangeWithUser(arg: {
    serial_number: string
    startDateTime: number
    endDateTime: number
  }): Promise<DeviceAlert[]> {
    const appSyncConnection = AppSyncClient.connection()
    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id ?? ''
    let merged: VehicleSubscriptionType[] | null = null
    let nextToken: string | null | undefined = undefined

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listVehicleSubscriptionBySnAndTopicAndDateTimeRange),
        variables: {
          topic: 'device_alert',
          user_id: user_id,
          serial_no: arg.serial_number,
          startDateTime: arg.startDateTime,
          endDateTime: arg.endDateTime,
          sortDirection: 'ASC',
          limit: ALERT_MAX_LIMIT,
          nextToken: nextToken,
        },
      })) as GraphQLResult<ListVehicleSubscriptionBySnAndTopicAndDateTimeRangeQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res.data?.listVehicleSubscriptionBySnAndTopicAndDateTimeRange
          ?.items as VehicleSubscriptionType[]) ?? []
      )
      nextToken =
        res.data?.listVehicleSubscriptionBySnAndTopicAndDateTimeRange?.nextToken
    }

    //有効なアラートのみを抽出
    const alertStatusKeys = Object.keys(ALERT_STATUS_MAP).map((key) =>
      Number(key)
    )

    return new Promise((resolve) => {
      const alerts = Alert.convertVehicleSubscriptionsToAlerts(merged ?? [])
      resolve(alerts.filter((alert) => alertStatusKeys.includes(alert.alert)))
    })
  }

  public static convertVehicleSubscriptionsToAlerts(
    vehicleSubscriptions: VehicleSubscriptionType[]
  ): DeviceAlert[] {
    const filtered = vehicleSubscriptions.filter(
      (vs: VehicleSubscriptionType | null) => {
        return !!vs
      }
    )
    return filtered.map((vs: VehicleSubscriptionType | null): DeviceAlert => {
      const alert: DeviceAlert = {} as DeviceAlert
      if (!vs) {
        return alert
      }

      const payload = JSON.parse(vs.payload)
      alert['id'] = vs.id
      alert['sn'] = vs.serial_no
      alert['dgn'] = payload.dgn
      alert['cat'] = Number(payload.cat)
      alert['alert'] = Number(payload.alert)
      alert['th'] = Number(payload.th)
      alert['dv'] = Number(payload.dv)
      alert['tm'] = vs.tm
      alert['lat'] = Number(payload.lat)
      alert['lon'] = Number(payload.lon)
      alert['trp'] = payload.trp
      alert['feature'] = Number(payload.feature)
      alert['mpg'] = Number(payload.mpg)
      alert['bitrate'] = payload.bitrate
      alert['fps'] = payload.fps
      alert['created_at'] = vs.created_at
      alert['ttl_timestamp'] = Number(vs.ttl_timestamp)
      return alert
    })
  }
}
